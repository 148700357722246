"use client";

import React, { FC } from "react";

import { SliderArrowsGroup, SwiperSlider, useSwiperRef } from "@causevest/ui-kit";
import { EntityCard } from "@components";
import { Box, Container, Stack, Typography } from "@mui/material";

import classes from "@lib/styles/mainpage.module.scss";
import { CampaignsList as CurrentCampaignsList } from "@lib/types";

interface Props {
  campaigns: CurrentCampaignsList;
  title: string;
}

export const CampaignsList: FC<Props> = ({ campaigns, title }) => {
  const [prevEl, prevElRef] = useSwiperRef<HTMLButtonElement>();
  const [nextEl, nextElRef] = useSwiperRef<HTMLButtonElement>();

  if (!campaigns?.data.length) {
    return null;
  }

  return (
    <Container role="section" className={classes.campaigns__wrapper}>
      <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
        <SliderArrowsGroup prevBtnRef={prevElRef} nextBtnRef={nextElRef} sizes={40} />
      </Stack>
      <Box className={classes.campaigns}>
        <SwiperSlider
          prevEl={prevEl}
          nextEl={nextEl}
          slidesPerView="auto"
          spaceBetween={30}
          slideClass={classes.campaigns__slide}
        >
          {campaigns.data.map((campaign, key) => (
            <EntityCard key={`mainpage_campaign_${key}`} campaign={campaign} />
          ))}
        </SwiperSlider>
      </Box>
    </Container>
  );
};
