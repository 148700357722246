import Link from "next/link";

import { FC } from "react";

import { GradientButton, InfoBlock } from "@causevest/ui-kit";
import { ChevronRight } from "@mui/icons-material";
import { Dialog, Stack, Typography } from "@mui/material";

import { AppRoutes } from "@lib/types";

import classes from "./DonateRejectPopup.module.scss";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const DonateRejectPopup: FC<Props> = ({ isOpen, onClose }) => (
  <Dialog open={isOpen} onClose={onClose}>
    <InfoBlock>
      <Stack className={classes.wrapper}>
        <Typography variant="h1" className={classes.title}>
          Uh oh!
        </Typography>
        <Typography className={classes.description}>
          Unfortunately, you are unable to vote for this campaign because you currently have no XCV
          votes to give.
        </Typography>
        <Typography className={classes.description}>
          Buy XCV votes directly from our ICO website to enjoy the voting experience.
        </Typography>
        <Link href={AppRoutes.MAIN} className={classes.link}>
          Purchase from here <ChevronRight />
        </Link>
      </Stack>
      <Stack flexDirection="row" justifyContent="flex-end" className={classes.footer}>
        <GradientButton onClick={onClose} className={classes.footer__btn}>
          Close
        </GradientButton>
      </Stack>
    </InfoBlock>
  </Dialog>
);
