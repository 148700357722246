"use client";

import { useCallback, useState } from "react";
import { toast } from "react-toastify";

import { AxiosError } from "axios";

import { handleErrorToast } from "@lib/helpers";
import {
  AdvancedAxiosConfig,
  CallbackType,
  EntityType,
  ErrorUnprocessable,
  ListFilters,
} from "@lib/types";

interface ReturnType<R> {
  getEntitiesList: (options?: AdvancedAxiosConfig, filters?: Partial<ListFilters>) => Promise<void>;
  isLoading: boolean;
  list?: EntityType<R>;
}

export const useEntitiesList = <T>(
  callback: CallbackType<T>,
  current?: EntityType<T>,
  successText?: string,
): ReturnType<T> => {
  const [isLoading, setLoading] = useState(false);
  const [list, setList] = useState<EntityType<T> | undefined>(current);

  const getEntitiesList = useCallback(
    async (options?: AdvancedAxiosConfig, filters?: Partial<ListFilters>) => {
      setLoading(true);

      try {
        const newList = await callback(options, filters);

        if (newList.data.length) {
          setList(newList);

          if (successText) {
            toast.success(successText, {
              theme: "colored",
            });
          }
        }
      } catch (err) {
        handleErrorToast((err as AxiosError).response?.data as ErrorUnprocessable);
      } finally {
        setLoading(false);
      }
    },
    [callback, successText],
  );

  return {
    isLoading,
    list,
    getEntitiesList,
  };
};
