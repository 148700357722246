import Link from "next/link";

import { FC } from "react";

import { GradientButton, InfoBlock } from "@causevest/ui-kit";
import { Box, Stack, Typography } from "@mui/material";

import classes from "./MonetaryInfoBlock.module.scss";

export interface MonetaryItem {
  amount: number;
  description: string;
  currency: string;
}

interface Props {
  items: MonetaryItem[];
  title: string;
  href: string;
}

export const MonetaryInfoBlock: FC<Props> = ({ items, title, href }) => (
  <InfoBlock
    title={title}
    headingButton={
      <Link href={href} style={{ textDecoration: "none" }}>
        <GradientButton sx={{ pl: "20px", pr: "20px" }}>See More</GradientButton>
      </Link>
    }
    withDecoration
  >
    <Stack flexDirection="row" alignItems="center" className={classes.wrapper}>
      {items.map((item, key, arr) => (
        <>
          <Stack key={item.description} className={classes.item}>
            <Typography className={classes.title}>
              {`${item.currency} ${item.amount}`.trim()}
            </Typography>
            <Typography className={classes.description}>{item.description}</Typography>
          </Stack>
          {key < arr.length - 1 && <Box className={classes.divider} />}
        </>
      ))}
    </Stack>
  </InfoBlock>
);
