import { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { MuiDatePicker, onInputErrorHandle } from "@causevest/ui-kit";
import { Stack } from "@mui/material";
import dayjs from "dayjs";

import common from "@lib/styles/common.module.scss";

import classes from "./DateRange.module.scss";

interface Props {
  isRequired?: boolean;
}

export const DateRange: FC<Props> = ({ isRequired }) => {
  const {
    watch,
    control,
    formState: { errors },
  } = useFormContext();
  const campaignGoalType = useWatch({ control, name: "toEndCampaign" });
  const startsAt = watch("starts_at");

  return (
    <Stack className={classes.dates}>
      <MuiDatePicker
        label="Select Campaign Start Date"
        name="starts_at"
        className={common.input}
        error={!!errors.starts_at}
        errorMessage={onInputErrorHandle(errors.starts_at?.message as string)}
        disablePast
        required={isRequired}
      />
      {campaignGoalType === "true" && (
        <MuiDatePicker
          label="Select Campaign End Date"
          name="ends_at"
          className={common.input}
          error={!!errors.ends_at}
          errorMessage={onInputErrorHandle(errors.ends_at?.message as string)}
          required={isRequired}
          minDate={dayjs(startsAt)}
          disablePast
        />
      )}
    </Stack>
  );
};
