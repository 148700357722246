"use client";

import { createTheme } from "@mui/material/styles";

import { roboto } from "@lib/fonts";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1200,
      xl: 1440,
    },
  },
  palette: {
    primary: {
      main: "#125cd5",
    },
    secondary: {
      main: "#E7F0FB",
    },
    success: {
      main: "#33A853",
    },
    error: {
      main: "#FE4949",
    },
    background: {
      default: "#F4F5FC",
      paper: "#ffffff",
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: roboto.style.fontFamily,
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
          fontSize: 12,
          textAlign: "left",
          maxWidth: 235,
        },
      },
    },
  },
});

export default theme;
