"use client";

import { FC, useMemo } from "react";
import { Line } from "react-chartjs-2";

import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  ScriptableContext,
  Title,
  Tooltip,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
);

export const WaveDiagram: FC = () => {
  const data = useMemo(
    () => ({
      labels: ["1W", "2W", "3W", "4W", "5W", "6W", "7W", "8W"],
      datasets: [
        {
          label: "Amount",
          data: [20, 28, 95, 30, 22, 25, 28, 32],
          backgroundColor: (context: ScriptableContext<"line">) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(1100, 270, 0, 0);
            gradient.addColorStop(0, "rgba(18, 92, 213, 0.23)");
            gradient.addColorStop(1, "#125CD5");
            return gradient;
          },
          borderColor: "rgba(18, 92, 213, 0.23)",
          borderWidth: 1,
          fill: "start",
          pointRadius: 0,
          pointHoverRadius: 0,
        },
        {
          label: "Likes",
          data: [45, 20, 48, 52, 43, 30, 26, 48],
          backgroundColor: (context: ScriptableContext<"line">) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(1000, 0, 0, 0);
            gradient.addColorStop(0, "rgba(0, 232, 254, 0.40)");
            gradient.addColorStop(1, "rgba(0, 232, 254, 0.31)");
            return gradient;
          },
          borderColor: "rgba(0, 232, 254, 0.40)",
          borderWidth: 1,
          fill: "start",
          pointRadius: 0,
          pointHoverRadius: 0,
        },
      ],
    }),
    [],
  );

  return (
    <Line
      data={data}
      options={{
        responsive: true,
        elements: {
          line: {
            tension: 0.4,
          },
        },
        scales: {
          y: {
            beginAtZero: false,
            suggestedMin: 1,
            ticks: {
              callback: (_: unknown, value: number) => `${value * 10}%`,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
          tooltip: {
            enabled: true,
            mode: "nearest",
            intersect: false,
          },
        },
      }}
      redraw={false}
    />
  );
};
