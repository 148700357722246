import { FC, ReactElement } from "react";

import { Box, Stack, SxProps, Typography } from "@mui/material";
import clsx from "clsx";

import classes from "./FeedBlock.module.scss";

interface Props {
  title: string;
  children: ReactElement | ReactElement[];
  className?: string;
  index?: number;
  sx?: SxProps;
  isLight?: boolean;
  headerActionBtn?: ReactElement;
}

export const FeedBlock: FC<Props> = ({
  title,
  index,
  children,
  className,
  sx,
  isLight,
  headerActionBtn,
}) => (
  <Box id={String(index)} className={clsx("feed_block", className, classes.wrapper)}>
    <Stack className={classes.heading}>
      <Typography variant="h2" className={clsx(classes.title, { [classes._light]: isLight })}>
        {title}
      </Typography>
      {headerActionBtn}
    </Stack>
    <Stack className={classes.feedInner} sx={sx}>
      {children}
    </Stack>
  </Box>
);
