import { FC } from "react";

import { Icon, MuiButton } from "@causevest/ui-kit";
import { Stack, Typography } from "@mui/material";

import classes from "./LockedPost.module.scss";

export const LockedPost: FC = () => (
  <Stack className={classes.wrapper}>
    <Icon
      src="/images/creator-img-stub.jpg"
      alt="post lock img"
      className={classes.bg}
      style={{ objectFit: "cover" }}
      sizes="100%"
      priority
      fill
    />
    <Stack className={classes.inner}>
      <Icon src="/images/padlock.svg" alt="post lock img" width={18} height={23} />
      <Typography className={classes.title}>
        Unlock this post by
        <br />
        becoming a Supporter
      </Typography>
      <MuiButton className={classes.btn}>Unlock</MuiButton>
    </Stack>
  </Stack>
);
