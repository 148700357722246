import { FC } from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";

import { Stack } from "@mui/material";

interface Props {
  url: string;
  title: string;
}

export const ShareBar: FC<Props> = ({ url, title }) => (
  <Stack flexDirection="row" alignItems="center" gap="20px">
    <FacebookShareButton url={url} title={title}>
      <FacebookIcon size={32} round />
    </FacebookShareButton>
    <TwitterShareButton url={url} title={title}>
      <XIcon size={32} round />
    </TwitterShareButton>
    <TelegramShareButton url={url} title={title}>
      <TelegramIcon size={32} round />
    </TelegramShareButton>
    <WhatsappShareButton url={url} title={title} separator=":: ">
      <WhatsappIcon size={32} round />
    </WhatsappShareButton>
    <LinkedinShareButton url={url} title={title}>
      <LinkedinIcon size={32} round />
    </LinkedinShareButton>
  </Stack>
);
