import Image from "next/image";
import Link from "next/link";

import { FC, memo } from "react";

import { SocialBar } from "@components";
import { Box, Container, Stack, Typography } from "@mui/material";

import { PLATFORM_SOCIAL } from "@lib/constants";
import { AppRoutes } from "@lib/types";

import classes from "./Footer.module.scss";

export const Footer: FC = memo(() => (
  <Stack className={classes.wrapper}>
    <Container sx={{ m: "auto" }}>
      <Stack className={classes.inner}>
        <Stack className={classes.left}>
          <Image src="/images/logo.svg" alt="logo" width={168} height={40} priority />
          <ul className={classes.menuList}>
            <li className={classes.menuList__item}>
              <Link href={AppRoutes.MAIN}>About</Link>
            </li>
            <li className={classes.menuList__item}>
              <Link href={AppRoutes.MAIN}>FAQs</Link>
            </li>
            <li className={classes.menuList__item}>
              <Link href={AppRoutes.MAIN}>Forum</Link>
            </li>
            <li className={classes.menuList__item}>
              <Link href={AppRoutes.MAIN}>Privacy Policy</Link>
            </li>
            <li className={classes.menuList__item}>
              <Link href={AppRoutes.MAIN}>Transparency</Link>
            </li>
            <li className={classes.menuList__item}>
              <Link href={AppRoutes.MAIN}>Terms</Link>
            </li>
          </ul>
          <Box className={classes.info}>
            <p>
              Email us at <Link href="mailto: info@causevest.io">info@causevest.io</Link>
            </p>
            <p>
              Call us at <Link href="tel: +442036370238 ">+442036370238 </Link>
              (Mon - Fri, 10am - 5pm PST)
            </p>
            <p>Causevest UK Limited 109-117 Middlesex St, London E1 7JF</p>
          </Box>
        </Stack>
        <Stack className={classes.right}>
          <Typography variant="h6">Connect with us:</Typography>
          <SocialBar social={PLATFORM_SOCIAL} isFooter />
        </Stack>
      </Stack>
    </Container>
  </Stack>
));

Footer.displayName = "Footer";
