"use client";

import { FC } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";

import { GradientButton, TextInput } from "@causevest/ui-kit";
import { Search } from "@mui/icons-material";
import { Box } from "@mui/material";
import clsx from "clsx";

import classes from "./SearchInput.module.scss";

interface Props {
  placeholder?: string;
  closeMobileSearch?: (isOpen: boolean) => void;
  isAdaptive?: boolean;
}

export const SearchInput: FC<Props> = ({
  placeholder = "Search for causes and campaigns",
  closeMobileSearch,
  isAdaptive,
}) => {
  const methods = useForm({
    defaultValues: {
      search: "",
    },
  });

  const onSubmit = (values: FieldValues) => {
    console.log("Search values: ", values);
  };

  return (
    <FormProvider {...methods}>
      <Box
        sx={{ position: "relative" }}
        className={clsx(classes.wrapper, {
          [classes._isMobile]: !!closeMobileSearch,
          [classes._isAdaptive]: isAdaptive,
        })}
      >
        <TextInput
          name="search"
          type="search"
          className={clsx(classes.input, {
            [classes._isMobile]: !!closeMobileSearch,
            [classes._isAdaptive]: isAdaptive,
          })}
          placeholder={placeholder}
        />
        <GradientButton
          className={clsx(classes.button, {
            [classes._isMobile]: !!closeMobileSearch,
            [classes._isAdaptive]: isAdaptive,
          })}
          onClick={methods.handleSubmit(onSubmit)}
        >
          {closeMobileSearch ? (
            <Search sx={{ color: "#ffffff", mt: "3px" }} fontSize="medium" />
          ) : (
            "Search"
          )}
        </GradientButton>
      </Box>
    </FormProvider>
  );
};
