"use client";

import { FC, ReactElement } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";

import { CurrencyInput, GradientButton, InfoBlock } from "@causevest/ui-kit";
import { Stack, Typography } from "@mui/material";

import classes from "./DonationForm.module.scss";

interface Props {
  title: string;
  subtitle?: string;
  placeholder?: string;
  children?: ReactElement | ReactElement[];
  footer?: ReactElement;
}

export const DonationForm: FC<Props> = ({ title, subtitle, placeholder, children, footer }) => {
  const methods = useForm({ defaultValues: { currency: "USD" } });

  const onSubmit = (values: FieldValues) => console.log("Donation started with values: ", values);

  return (
    <>
      <Typography variant="h2" className={classes.title}>
        {title}
      </Typography>
      <InfoBlock>
        <FormProvider {...methods}>
          <Stack className={classes.wrapper}>
            {!!subtitle && (
              <Typography variant="h2" className={classes.subtitle}>
                {subtitle}
              </Typography>
            )}
            {!!placeholder && <CurrencyInput placeholder={placeholder} />}
            {children}
            <GradientButton className={classes.btn} onClick={methods.handleSubmit(onSubmit)}>
              Donate Now
            </GradientButton>
            {footer}
          </Stack>
        </FormProvider>
      </InfoBlock>
    </>
  );
};
