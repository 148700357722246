import { FC } from "react";

import { Icon, InfoBlock } from "@causevest/ui-kit";
import { Stack, Typography } from "@mui/material";
import clsx from "clsx";

import classes from "./StatisticPanel.module.scss";

interface Props {
  likes: number;
  donors: number;
  xcvCount: number;
}

export const StatisticPanel: FC<Props> = ({ likes, donors, xcvCount }) => (
  <InfoBlock innerClass={classes.wrapper}>
    <Stack className={classes.item}>
      <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
        <Typography className={classes.counter}>{likes}</Typography>
        <Icon src="/images/like-big.svg" width={22} height={22} alt="likes icon" />
      </Stack>
      <Typography className={classes.title}>Total Likes</Typography>
    </Stack>
    <Stack className={clsx(classes.item, classes._second)}>
      <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
        <Typography className={classes.counter}>{donors}</Typography>
        <Icon src="/images/people-big.svg" width={22} height={22} alt="people icon" />
      </Stack>
      <Typography className={classes.title}>Total Donors</Typography>
    </Stack>
    <Stack className={classes.item}>
      <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
        <Typography className={classes.counter}>{xcvCount}</Typography>
        <Icon src="/images/logo-short.svg" width={22} height={22} alt="causevest icon" />
      </Stack>
      <Typography className={classes.title}>XCV Available</Typography>
    </Stack>
  </InfoBlock>
);
