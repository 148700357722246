import { FC, memo } from "react";

import { Icon } from "@causevest/ui-kit";
import { Stack, Typography } from "@mui/material";

import classes from "./UserInList.module.scss";

interface Props {
  name: string;
  image: string;
}

export const UserInList: FC<Props> = memo(({ name, image }) => (
  <Stack flexDirection="row" alignItems="center" gap="8px">
    <Icon
      src={image.length > 0 ? image : "/images/logo-short.svg"}
      alt="supporter img"
      width={26}
      height={26}
      className={classes.image}
      style={{ objectFit: "cover" }}
      priority
    />
    <Typography className={classes.title}>{name}</Typography>
  </Stack>
));

UserInList.displayName = "UserInList";
