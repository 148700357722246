import Link from "next/link";

import { FC } from "react";

import { setCookies } from "@/src/app/actions";
import { MuiButton } from "@causevest/ui-kit";
import { Stack, Typography } from "@mui/material";

import { AppRoutes } from "@lib/types";

import classes from "./AcceptCookie.module.scss";

export const AcceptCookie: FC = () => (
  <form action={setCookies}>
    <Stack className={classes.wrapper}>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        className={classes.inner}
      >
        <Typography className={classes.title}>
          We use cookies to improve your experience using this site.{" "}
          <Link href={AppRoutes.MAIN}>More Information</Link>
        </Typography>
        <MuiButton type="submit" className={classes.btn}>
          Accept
        </MuiButton>
      </Stack>
    </Stack>
  </form>
);
