import React, { FC } from "react";

import { Box, Container, Stack, SxProps, Typography } from "@mui/material";

import common from "@lib/styles/mainpage.module.scss";
import { AppRoutes, CategoriesList } from "@lib/types";

import classes from "./Categories.module.scss";
import { MainCategory } from "./MainCategory";

const MAX_CATEGORIES = 14;

interface Props {
  categories: CategoriesList;
  sx?: SxProps;
}

export const Categories: FC<Props> = ({ categories, sx }) => (
  <Box className={classes.categories__wrapper} sx={sx}>
    <Container>
      <Typography variant="h2" className={common.title}>
        Featured Campaign Categories
      </Typography>
      <Stack flexDirection="row" alignItems="center" flexWrap="wrap" className={classes.categories}>
        {categories.data.map(
          (category, key) =>
            key <= MAX_CATEGORIES && (
              <MainCategory
                key={category.uuid}
                name={category.name}
                alt={category.name}
                imgUrl={category.image}
              />
            ),
        )}
        <MainCategory
          name="Others"
          alt="Others"
          url={`${AppRoutes.CAMPAIGNS}?category=all#campaigns-list`}
        />
      </Stack>
    </Container>
  </Box>
);
