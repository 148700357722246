import React, { FC } from "react";

import { Alert, Snackbar } from "@mui/material";
import { AlertColor } from "@mui/material/Alert/Alert";

interface Props {
  timeout: number;
  isOpen: boolean;
  severity?: AlertColor;
  message?: string;
}

export const ToastMessage: FC<Props> = ({ timeout, message, isOpen, severity }) => (
  <Snackbar
    open={isOpen}
    autoHideDuration={timeout}
    anchorOrigin={{ vertical: "top", horizontal: "right" }}
  >
    <Alert severity={severity} variant="filled" sx={{ width: "100%" }}>
      {message}
    </Alert>
  </Snackbar>
);
