import { FC } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";

import { SecondaryButton, TextInput } from "@causevest/ui-kit";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import clsx from "clsx";
import * as yup from "yup";

import classes from "./SearchInputSmall.module.scss";

interface Props {
  onSubmit: (values: FieldValues) => void;
  searchCauses?: boolean;
  disabled?: boolean;
}

const schema = yup.object().shape({
  search: yup.string(),
});

export const SearchInputSmall: FC<Props> = ({ onSubmit, searchCauses, disabled }) => {
  const methods = useForm({ resolver: yupResolver(schema), defaultValues: { search: "" } });
  const { handleSubmit } = methods;

  const placeholder = searchCauses ? "Search for Causes" : "Search for a Cause or category";

  const onHandleSubmit = (values: FieldValues) => onSubmit(values);

  return (
    <FormProvider {...methods}>
      <Box className={classes.wrapper}>
        <TextInput
          name="search"
          type="search"
          className={clsx(classes.input, { [classes._long]: searchCauses })}
          iconUrl="/images/search.svg"
          placeholder={placeholder}
          autoComplete="off"
          disabled={disabled}
          aria-hidden={false}
        />
        {searchCauses && (
          <SecondaryButton
            variant="outlined"
            className={classes.submitBtn}
            onClick={handleSubmit(onHandleSubmit)}
          >
            Search
          </SecondaryButton>
        )}
      </Box>
    </FormProvider>
  );
};
