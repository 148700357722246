import Link from "next/link";

import React, { FC, memo } from "react";

import { Icon } from "@causevest/ui-kit";
import { Stack, Typography } from "@mui/material";
import clsx from "clsx";

import { AppRoutes } from "@lib/types";

import classes from "./Categories.module.scss";

interface Props {
  name: string;
  alt: string;
  imgUrl?: string;
  url?: string;
}

export const MainCategory: FC<Props> = memo(({ name, alt, imgUrl, url }) => (
  <Link
    href={url ?? `${AppRoutes.CAMPAIGNS}?category=${encodeURI(name)}#campaigns-list`}
    style={{ textDecoration: "none" }}
  >
    <Stack className={classes.categories__item}>
      <Icon
        src={imgUrl ?? "/images/logo-short.svg"}
        alt={alt}
        style={{ objectFit: "cover" }}
        className={clsx(classes.categories__pic, classes._last)}
        sizes="100%"
        fill
      />
      <Typography className={classes.categories__name}>{name}</Typography>
    </Stack>
  </Link>
));

MainCategory.displayName = "Category";
