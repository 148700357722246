import React, { memo } from "react";

export const CampaignsIcon = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
  >
    <path d="M12 2L2 7 12 12 22 7 12 2z" />
    <path d="M2 17L12 22 22 17" />
    <path d="M2 12L12 17 22 12" />
  </svg>
));

CampaignsIcon.displayName = "CampaignsIcon";
