"use client";

import { useRouter } from "next/navigation";

import { FC, useState } from "react";
import { toast } from "react-toastify";

import { GradientButton, Preloader } from "@causevest/ui-kit";
import { Stack, Typography } from "@mui/material";
import { AxiosError } from "axios";

import { authAPI } from "@api";

import { handleErrorToast } from "@lib/helpers";
import { AppRoutes, ErrorUnprocessable } from "@lib/types";

import classes from "./EmailVerifyInner.module.scss";

export const EmailVerifyInner: FC = () => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);

  const resendEmailVerification = async () => {
    setIsLoading(true);
    try {
      await authAPI.resendEmailVerification();
      toast.success("Verifcation email was successfully sent. Check your email");
      router.push(AppRoutes.MAIN);
    } catch (err) {
      const error = err as AxiosError;
      handleErrorToast(error.response?.data as ErrorUnprocessable);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Stack justifyContent="space-around" sx={{ minHeight: "calc(100vh - 410px)" }}>
        <Preloader />
      </Stack>
    );
  }

  return (
    <Stack className={classes.inner}>
      <Typography variant="h1" className={classes.title}>
        Please, hit the button to receive <br />
        new verification email
      </Typography>
      <GradientButton className={classes.btn} onClick={resendEmailVerification}>
        Verify Email
      </GradientButton>
    </Stack>
  );
};
