import Link from "next/link";

import { FC } from "react";

import { Icon, InfoBlock } from "@causevest/ui-kit";
import { Stack, Typography } from "@mui/material";
import clsx from "clsx";

import { AppRoutes } from "@lib/types";

import classes from "./Messages.module.scss";

export interface MockedMessagesData {
  link: AppRoutes;
  type: string;
  image: string;
  time: string;
  title?: string;
  by?: string;
}

interface Props {
  messages?: MockedMessagesData[];
}

export const Messages: FC<Props> = ({ messages }) => {
  if (!messages) {
    return (
      <InfoBlock>
        <Stack alignItems="center" className={classes.empty}>
          <Icon src="/images/no-notification.svg" width={124} height={90} alt="no notifications" />
          <Typography variant="h1" className={classes.empty__title}>
            You currently have (0) Notifications
          </Typography>
        </Stack>
      </InfoBlock>
    );
  }

  return (
    <InfoBlock title="Notification(s)">
      <Stack className={classes.wrapper}>
        {messages.map((item, key) => (
          <Link key={`notification_${key}`} href={item.link} style={{ textDecoration: "none" }}>
            <Stack flexDirection="row" alignItems="flex-start" justifyContent="space-between">
              <Stack flexDirection="row" alignItems="flex-start" className={classes.inner}>
                <Icon src={item.image} alt="message pic" width={16} height={16} />
                <Stack>
                  {!!item.title && (
                    <Typography
                      className={clsx(classes.title, {
                        [classes._declined]: item.type === "declined",
                      })}
                    >
                      {item.title}
                    </Typography>
                  )}
                  {!!item.by && <Typography className={classes.by}>By Mathew Akingbade</Typography>}
                </Stack>
              </Stack>
              <Typography className={classes.time}>{item.time}</Typography>
            </Stack>
          </Link>
        ))}
      </Stack>
    </InfoBlock>
  );
};
