import Link from "next/link";

import React, { FC } from "react";

import { Stack } from "@mui/material";
import clsx from "clsx";

import classes from "./SocialBar.module.scss";
import { DiscordIcon } from "./icons/DiscordIcon";
import { TelegramIcon } from "./icons/TelegramIcon";
import { TwitterIcon } from "./icons/TwitterIcon";
import { YoutubeIcon } from "./icons/YoutubeIcon";

interface Props {
  social?: Record<string, string>;
  isFooter?: boolean;
}

export const SocialBar: FC<Props> = ({ social, isFooter }) => {
  if (!social || !Object.keys(social).length) {
    return null;
  }

  return (
    <Stack flexDirection="row" alignItems="center" className={classes.wrapper}>
      {!!social.telegram && (
        <Link
          href={social.telegram}
          className={clsx(classes.socialItem, { [classes._footer]: isFooter })}
          target="_blank"
        >
          <TelegramIcon />
        </Link>
      )}
      {!!social.discord && (
        <Link
          href={social.discord}
          className={clsx(classes.socialItem, { [classes._footer]: isFooter })}
          target="_blank"
        >
          <DiscordIcon />
        </Link>
      )}
      {!!social.twitter && (
        <Link
          href={social.twitter}
          className={clsx(classes.socialItem, { [classes._footer]: isFooter })}
          target="_blank"
        >
          <TwitterIcon />
        </Link>
      )}
      {!!social.youtube && (
        <Link
          href={social.youtube}
          className={clsx(classes.socialItem, { [classes._footer]: isFooter })}
          target="_blank"
        >
          <YoutubeIcon />
        </Link>
      )}
    </Stack>
  );
};
