import Link from "next/link";

import React, { FC } from "react";

import { Icon } from "@causevest/ui-kit";
import { Box, Stack } from "@mui/material";

import { AppRoutes } from "@lib/types";

interface Props {
  count: number;
}

export const Envelope: FC<Props> = ({ count }) => (
  <Link href={AppRoutes.MAIN}>
    <Stack sx={{ position: "relative" }}>
      <Icon
        src="/images/envelope.svg"
        alt="envelope"
        width={24}
        height={20}
        sx={{ height: "20px", ml: "10px" }}
      />
      {count > 0 && (
        <Box
          sx={{
            position: "absolute",
            top: "-6px",
            right: "-8px",
            color: "#125cd5",
            fontSize: "9px",
            fontWeight: 500,
          }}
        >
          {count}
        </Box>
      )}
    </Stack>
  </Link>
);
